@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cardo:ital,wght@0,400;0,700;1,400&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cardo:ital,wght@0,400;0,700;1,400&family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Playfair+Display:ital,wght@0,400;0,500;0,600;0,700;0,800;0,900;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');


@tailwind base;
@tailwind components;
@tailwind utilities;

.lg-react-element {
  display:flex;
  flex-wrap: wrap;
}
.nav-link{
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background-image: linear-gradient(to right, #9a8a8b, #9a8a8b 50%, #fff 50%);
  background-size: 200% 100%;
  background-position: 100%;
  position: relative;
  font-size: 20px;
}
.nav-link:hover {
  transition: all 0.5s cubic-bezier(0, 0, 0.23, 1);
  background-position: 0%;
}
.Content-Text{
  @apply flex items-center pl-6
}
.Content-Text h1{
  @apply text-[#9a8a8b] font-press-start-lato text-xl  md:text-4xl
}
.Content-Text span{
  @apply text-[#848e83] ml-1 font-press-start-lato text-xl  md:text-4xl
}
.nav-item a{
  color: red !important;
}
.nav-item .active{
  background-color: #cabdb4 !important;
  color: red !important;

}